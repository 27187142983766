import React, { useState } from "react";
import { useEffect } from "react";
import {
    getAmountInEther,
    getAmountInWai,
    getAvailableToRemove,
    withdraw,
    withdrawWithFee,
    is_Enabled,
} from "../../utils/web3Services";
import "./Unstake.css";
import BN from "bn.js";

export const Unstake = () => {
    const [awailableToWithdraw, setAwailableToWithdraw] = useState(0);
    const [toShowAmount, setToShowAmount] = useState("0.00");
    const [withdrawAmount, setWithdrawAmount] = useState("0.00");
    const [amountToSend, setAmountToSend] = useState(0);
    const getAvialble = async () => {
        try {
            const balance = await getAvailableToRemove();
            setAwailableToWithdraw(balance);
            const show = await getAmountInEther(balance);
            setToShowAmount(Number(show).toFixed(2));
        } catch (error) {
            console.log(error);
        }
    };

    const get_is_enabled = async () => {
        try {
            const enabled = await is_Enabled();
            console.log("enabled ",enabled)
        }
        catch(err) {
            console.log("err" ,err);
        }
    }
    const calculateByPercent = async (percent) => {
        try {
            const a = new BN(awailableToWithdraw);
            const b = new BN(percent);
            const c = new BN(100);
            const toSend = new BN(a.mul(b));
            const amount = await getAmountInEther(toSend.div(c).toString());
            setWithdrawAmount(Number(amount).toFixed(2));
            setAmountToSend(toSend.div(c).toString());
        } catch (error) {
            console.log(error);
        }
    };

    const withdrawNormal = async () => {
        try {
            await withdraw(amountToSend);
        } catch (error) {
            console.log(error);
        }
    };

    const withdrawWithCharges = async () => {
        try {
            await withdrawWithFee(amountToSend);
        } catch (error) {
            console.log(error);
        }
    };

    const setAmount = async (value) => {
        try {
            const amount = getAmountInWai(value);
            setWithdrawAmount(value);
            setAmountToSend(amount.toString());
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAvialble();
        get_is_enabled()
    }, []);
    return (
        <div className="unstake-box-container">
            <div className="unstake-box-heading">Unstake</div>
            <div className="unstake-container2">
                <div className="unstake-container2-heading1">
                    Amount
                    <input
                        type="text"
                        placeholder="11111111"
                        value={withdrawAmount}
                        onChange={(e) => setAmount(e.target.value)}
                    ></input>
                </div>
                <div className="unstake-container2-heading2">
                    TGK Token
                    <div className="unstake-b44">
                        <div className="b81">
                            <button
                                className="i25"
                                onClick={() => calculateByPercent(25)}
                            >
                                25%
                            </button>
                        </div>
                        <div className="b82">
                            <button
                                className="i50"
                                onClick={() => calculateByPercent(50)}
                            >
                                50%
                            </button>
                        </div>
                        <div className="b83">
                            <button
                                className="i75"
                                onClick={() => calculateByPercent(75)}
                            >
                                75%
                            </button>
                        </div>
                        <div className="b84">
                            <button
                                className="i100"
                                onClick={() => calculateByPercent(100)}
                            >
                                100%
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="unstake-box-rewards">
                Available to Withdraw: {toShowAmount}
            </div>
            <div className="b45">
                <div className="b-immediate">
                    <button className="immediate" onClick={withdrawWithCharges}>
                        Immediate Withdraw
                    </button>
                </div>
                <div className="b-unstake-token">
                    <button className="unstake-token" onClick={withdrawNormal}>
                        Withdraw
                    </button>
                </div>
            </div>
            <div className="unstake-content">
                There is an 8 day cool off period when unstaking. After the cool
                off period you can claim your tokens with no penalty. If you
                change the amount you wish to unstake during the cool off period
                you need to cancel and start again. If you wish to unstake
                immediately there is a 7% transaction fee. Rewards can be
                claimed or staked immediately with no penalty.
            </div>
        </div>
    );
};
