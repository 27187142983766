import Web3 from "web3";
import BigNumber from "bignumber.js";
import BN from "bn.js";

import {
    mintingAbi,
    mintingAddress,
    routerAbi,
    routerAddress,
    rpc,
    stakingAbi,
    stakingAddress,
    tgkTokenAbi,
    tgkTokenAddress,
    USDTAddress,
    WEthAddress,
} from "./config";

const getRouterInstance = () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    return new web3.eth.Contract(routerAbi, routerAddress);
};

const getTokenInstance = () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    return new web3.eth.Contract(tgkTokenAbi, tgkTokenAddress);
};

const getUSDTInstance = () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    return new web3.eth.Contract(tgkTokenAbi, USDTAddress);
};

const getAddress = async () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    const accounts = await web3.eth.getAccounts();
    return accounts[0];
};

const approveTGK = async (amount, address) => {
    const tgkContract = getTokenInstance();
    const userAddress = await getAddress();
    await tgkContract.methods
        .approve(address ? address : routerAddress, amount)
        .send({
            from: userAddress,
        });
};

const approveUSDC = async (amount) => {
    const USDCTContract = getUSDTInstance();
    const userAddress = await getAddress();
    await USDCTContract.methods.approve(routerAddress, amount).send({
        from: userAddress,
    });
};

export const getAmountInWai = async (amount, usdt) => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    if (usdt) {
        const wei = await withDecimals(amount);
        return wei;
    }
    return web3.utils.toWei(Number(amount).toFixed(18).toString());
};

export const getAmountInEther = async (amount, usdt) => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    if (usdt) {
        return await withoutDecimals(amount);
    }
    return web3.utils.fromWei(amount.toString(), "ether");
};

export const getBalanceOfEtherWallet = async () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    const userAddress = await getAddress();
        const getBalance = await web3.eth.getBalance(userAddress)
        console.log("getBalance ",getBalance)
    return (Number(getBalance)/10**18).toFixed(4)
}
export const getBalanceOfUsdt = async () => {
    const USDCTContract = getUSDTInstance();
    const userAddress = await getAddress();
    let decimal = await USDCTContract.methods.decimals().call();
    console.log("decimal ",decimal)
    const amount = await USDCTContract.methods.balanceOf(userAddress).call()
    console.log("amount ",amount)
    return (Number(amount) / 10** Number(decimal)).toFixed(2)
}

export const withoutDecimals = async (amount) => {
    const USDCTContract = getUSDTInstance();

    let decimal = USDCTContract.methods.decimals().call();

    decimal = new BigNumber(decimal);
    const base = new BigNumber(10);
    const amount_usdt = new BigNumber(amount);
    console.log("amount_usdt ", amount_usdt.toString());
    const toPoint = new BigNumber(base.pow(decimal).toString());
    console.log("toPoint ", toPoint.toString());
    return amount_usdt.div(toPoint.toString()).toString();
};

export const withDecimals = async (amount) => {
    const USDCTContract = getUSDTInstance();
    let decimal = await USDCTContract.methods.decimals().call();
    decimal = new BigNumber(decimal);
    const base = new BigNumber(10);
    const amount_usdt = new BigNumber(amount);
    const toPoint = new BigNumber(base.pow(decimal).toString());
    return toPoint.times(amount_usdt).toString();
};

// The  buy TGK
export const getAmountOut = async (amountIn, path, usdt) => {
    const routerContract = getRouterInstance();
    const amountCoonverted = await getAmountInWai(amountIn, usdt);
    console.log(amountCoonverted);
    return await routerContract.methods
        .getAmountsOut(amountCoonverted, path)
        .call();
};

export const getTimeInSeconds = (duration) => {
    return (new Date().getTime() / 1000 + duration * 1000 * 60)
        .toFixed(0)
        .toString();
};

export const swapExactTGKForEth = async (amountIn, amountOutMin, txTime, expertMode) => {
    console.log(amountOutMin, "outMin");
    const amount_min = expertMode ? 0 : amountOutMin
    const routerContract = getRouterInstance();
    const userAddress = await getAddress();
    const inputAmout = await getAmountInWai(amountIn);
    await approveTGK(inputAmout);
    return await routerContract.methods
        .swapExactTokensForETHSupportingFeeOnTransferTokens(
            inputAmout,
            amount_min,
            [tgkTokenAddress, WEthAddress],
            userAddress,
            getTimeInSeconds(txTime)
        )
        .send({ from: userAddress });
};

export const swapExactEthForTGK = async (amountIn, amountOutMin, txTime, expertMode) => {
    console.log(amountOutMin, "outMin");
    const amount_min = expertMode ? 0 : amountOutMin
    const routerContract = getRouterInstance();
    const userAddress = await getAddress();
    return await routerContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(
            amount_min,
            [WEthAddress, tgkTokenAddress],
            userAddress,
            getTimeInSeconds(txTime)
        )
        .send({ from: userAddress, value: await getAmountInWai(amountIn) });
};

export const swapExactTGKForTokens = async (amountIn, amountOutMin, txTime,expertMode) => {
    const amount_min = expertMode ? 0 : amountOutMin
    const routerContract = getRouterInstance();
    const userAddress = await getAddress();
    const inputAmout = await getAmountInWai(amountIn);
    await approveTGK(inputAmout);
    return await routerContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(
            inputAmout,
            amount_min,
            [tgkTokenAddress, WEthAddress],
            userAddress,
            getTimeInSeconds(txTime)
        )
        .send({ from: userAddress });
};

export const swapExactTokensForTGK = async (
    amountIn,
    amountOutMin,
    txTime,
    usdt,
    expertMode
) => {
    console.log(amountOutMin, "outMin");
    const amount_min = expertMode ? 0 : amountOutMin
    const routerContract = getRouterInstance();
    const userAddress = await getAddress();
    const inputAmout = await getAmountInWai(amountIn, usdt);
    await approveUSDC(inputAmout);
    return await routerContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(
            inputAmout,
            amount_min,
            [USDTAddress, tgkTokenAddress],
            userAddress,
            getTimeInSeconds(txTime)
        )
        .send({ from: userAddress });
};

// Mint NFT
const getMintContractInstance = async () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    return new web3.eth.Contract(mintingAbi, mintingAddress);
};

export const getTotalPrice = async (quantity) => {
    const mintingCotract = await getMintContractInstance();
    const costPerNft = await mintingCotract.methods.publicSalePrice().call();
    return (costPerNft * quantity).toString();
};

export const getTotalMinted = async () => {
    const mintingCotract = await getMintContractInstance();
    const totalMinted = await mintingCotract.methods.totalSupply().call();
    return totalMinted;
};

export const mintNft = async (quantity) => {
    const mintingCotract = await getMintContractInstance();
    const userAddress = await getAddress();
    const totalPrice = await getTotalPrice(quantity);
    return await mintingCotract.methods.publicSaleMint(quantity).send({
        from: userAddress,
        value: totalPrice,
    });
};

// Staking

const getStakingContact = () => {
    const web3 = new Web3(Web3.givenProvider || rpc);
    return new web3.eth.Contract(stakingAbi, stakingAddress);
};

export const getStakeAmount = async (level) => {
    // const stakingContract = getStakingContact();
    let amountToStake;
    if (level === "silver") {
        // amountToStake = await stakingContract.methods.silverPrice().call()
        amountToStake = 10;
    } else if (level === "gold") {
        // amountToStake = await stakingContract.methods.goldPrice().call()
        amountToStake = 15;
    } else if (level === "diamond") {
        // amountToStake = await stakingContract.methods.diamondPrice().call()
        amountToStake = 20;
    } else {
        // amountToStake = await stakingContract.methods.basicPrice().call()
        amountToStake = 5;
    }
    // return await getAmountInEther(amountToStake)
    return getAmountInWai(amountToStake);
};

export const getAvailableToStake = async () => {
    const tgkContract = getTokenInstance();
    const userAddress = await getAddress();
    const balance = await tgkContract.methods.balanceOf(userAddress).call();
    return getAmountInEther(balance);
};

export const getTGKValue = async (amount) => {
    const stakingContract = getStakingContact();
    const tgkAmount = await stakingContract.methods
        .getTokensFromPrice(amount)
        .call();
    return tgkAmount;
};
 
export const stake = async (amount) => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    // const tgkAmount = await stakingContract.methods
    //     .getTokensFromPrice(amount)
    //     .call();
    await approveTGK(amount, stakingAddress);
    return await stakingContract.methods.deposit(amount).send({
        from: userAddress,
    });
};
// canWithdrawWithoutFees
export const is_Enabled = async () => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    return await stakingContract.methods.canWithdrawWithoutFees(userAddress).call();
}
export const earned = async () => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    const reward = await stakingContract.methods.rewardOf(userAddress).call();
    return getAmountInEther(reward);
};

export const claimReward = async () => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    return await stakingContract.methods.claimRewards().send({
        from: userAddress,
    });
};

export const stakeRewards = async () => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    return await stakingContract.methods.stakeReward().send({
        from: userAddress,
    });
};

export const getAvailableToRemove = async () => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    const balance = await stakingContract.methods
        .amountStaked(userAddress)
        .call();
    return balance;
};

export const withdraw = async (amount) => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    await stakingContract.methods.withdraw(amount).send({
        from: userAddress,
    });
};

export const withdrawWithFee = async (amount) => {
    const stakingContract = getStakingContact();
    const userAddress = await getAddress();
    await stakingContract.methods.withdrawAndPayFees(amount).send({
        from: userAddress,
    });
};

export const totalStaked = async () => {
    const stakingContract = getStakingContact();
    const amount = await stakingContract.methods._totalStaked().call();
    return (Number(amount) / 10 ** 18).toFixed(2);
};

export const staker = async () => {
    const stakingContract = getStakingContact();
    const arr = await stakingContract.methods.stakers().call();
    return arr.length;
};

export const rewarded = async () => {
    const stakingContract = getStakingContact();
    const rewards = await stakingContract.methods.rewarded().call();
    return (Number(rewards) / 10 ** 18).toFixed(2);
};

export const totalStakedValue = async () => {
    const stakingContract = getStakingContact();
    const address = await getAddress();
    const total_staked_value = await stakingContract.methods
        .staked(address)
        .call();
    return (Number(total_staked_value) / 10 ** 18).toFixed(2);
};

export const totalRewardsUnclaimed = async () => {
    const stakingContract = getStakingContact();
    const address = await getAddress();
    const total_rewards_unclaimed = await stakingContract.methods
        .rewardOf(address)
        .call();
    return (Number(total_rewards_unclaimed) / 10 ** 18).toFixed(2);
};

export const switchChain = async () => {
    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x4" }], // chainId must be in hexadecimal numbers
        });
    } catch (err) {
        if (err.code === 4902)
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                        chainId: "0x4",
                        rpcUrls: ["https://rinkeby.infura.io/v3/"],
                        chainName: "Rinkeyby Testnet",
                        nativeCurrency: {
                            name: "ETH",
                            symbol: "ETH", // 2-6 characters long
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://rinkeby.etherscan.io"],
                    },
                ],
            });
    }
};
