import React from 'react';
import './HeroSection.css';
import Swap from '../../../components/Swap/Swap';
import background from '../../../assets/background.png'
import Header from '../../../components/Header/Header';
function HeroSection(){
    return(
        <div className="hero-section">
            <Header/>
            <div className="image-div"><img src={background} alt="back" className='bg-image'/></div>
           <Swap/>
        </div>
    )
}

export default HeroSection;