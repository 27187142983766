import React from 'react'
import './Generic.css'

export const Generic = ({total_staked,rewards}) => {
  return (
    <div className="generic-container">
        <div className="generic-container-heading">
            Generic Portfolio
        </div>
        <div className="generic-container-content">
            <div className="total">
                <p>Total staked</p>
                <p>{total_staked}</p>
            </div>
            <div className="staker">
                <p>Staker</p>
                <p>0</p>
            </div>
            <div className="rewards">
                <p>Total Rewards Distributed</p>
                <p>{rewards}</p>
            </div>
            <div className="apy">
                <p>APY</p>
                <p>275</p>
            </div>
        </div>
    </div>
  )
}
