import React, { useState } from 'react'
import { useEffect } from 'react'
import { claimReward, earned, stakeRewards } from '../../utils/web3Services'
import './Reward.css'
import ButtonLoading from "../ButtonLoading/ButtonLoading"

export const Reward = () => {
  const [reward,setReward] = useState("0.0000")
  const [loading,setLoading] = useState(true)
  const getEarned = async() =>{
    try {
      setLoading(true)

      const rewardOfUser = await earned()
      setReward(rewardOfUser)
      setLoading(false)

    } catch (error) {
      console.log(error)
      setLoading(false)

    }
  }

  const stakeReward = async() =>{
    try {
      setLoading(true)
      await stakeRewards()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const getReward = async()=>{
    try {
      setLoading(true)
      await claimReward()
      setLoading(false)

    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(()=>{
    getEarned()
  },[])
  return (
    <div className="reward-container">
        <div className="reward-heading">
            You have earned
        </div>
        <div className="amount">
            <p>{Number(reward).toFixed(4)} <span>Rewards</span></p>
        </div>
        <div className="b15">
            <div className='b-claim'><button className='claim' onClick={getReward}>Claim Rewards <ButtonLoading loading={loading} color={"gold"}/></button></div>
            <div className='b-token'><button className='token' onClick={stakeReward}>Stake Tokens  <ButtonLoading loading={loading} color={"gold"}/></button></div>
        </div> 
    </div>
  )
}
