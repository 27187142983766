import { useEffect, useState } from "react";
import fireFlyImg from '../../assets/svgs/bgEllipseRoadmap.svg'

import './fireflies.css'

const FireFlies = ({ reference }) => {
  const [winHeight, setWinHeight] = useState(0);
  const [winWidth, setWinWidth] = useState(0);
  const [flies, setFlies] = useState([]);

  const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  useEffect(() => {
    setFlies(document.querySelectorAll(".firefly"));
    if(reference && reference.current){
        setWinWidth(reference.current.clientWidth);
        setWinHeight(reference.current.clientHeight);
    }
        // console.log(reference)

    for (var i = 0; i < flies.length; i++) {
      var thisFly = flies[i];

      const randomTop = getRandomNumber(0, winHeight);
      const randomLeft = getRandomNumber(0, winWidth);

      thisFly.style.top = randomTop + "px";
      thisFly.style.left = randomLeft + "px";
    }
  }, [reference, reference.current]);

  return (
      <>
            <img src={fireFlyImg} alt="firefly" className="firefly" />
            <img src={fireFlyImg} alt="firefly" className="firefly" />
            <img src={fireFlyImg} alt="firefly" className="firefly" />
            <img src={fireFlyImg} alt="firefly" className="firefly" />
            <img src={fireFlyImg} alt="firefly" className="firefly" />
            <img src={fireFlyImg} alt="firefly" className="firefly" />
      </>
  );
};

export default FireFlies;
