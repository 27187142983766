import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import {switchChain} from "../utils/web3Services"
export const Web3Context = createContext()

const { ethereum } = window

export const Web3Provider = ({children}) => {
  const [metamaskAddress, setMetamaskAddress] = useState('')
  const [isWalletConnected, setIsWalletConnected] = useState(false)

  const checkMetamaskWallet = async () => {
    const res = await ethereum.request({
        method: "eth_accounts"
    })
    if(res){
        setMetamaskAddress(res[0])
        sessionStorage.setItem("metamask_wallet",res[0])
    }
  }

  const connectMetamaskWallet = async () => {
    try{
        if(!ethereum) return ({ metamask: false })
        await switchChain();
        const res = await ethereum.request({
            method: "eth_requestAccounts",
        })
        setIsWalletConnected(true)
        sessionStorage.setItem("isConnected",true);
        
        if(res) checkMetamaskWallet()
        return res

    } catch (err){
        console.error("Connect metamask wallet error: ",err)
    }
  }

  useEffect(() => {
    checkMetamaskWallet()
  },[])

  useEffect(() => {
    setMetamaskAddress(sessionStorage.getItem("metamask_wallet"))
    setIsWalletConnected(true)
  },[metamaskAddress])
  return (
    <Web3Context.Provider value={{connectMetamaskWallet, metamaskAddress, isWalletConnected}} >
        {children}
    </Web3Context.Provider>
  )
}