import React from 'react'
import './Wallet_Error.css'

export const WalletError = ({Src,name}) => {
  return (
    <div className="wallet-error-container">
        <div className="error-heading">
            {name} Not Detected
        </div>
        <div className="error-logo">
          <Src className='wallet-logo'/>
        </div>
        <a href='https://metamask.io/download/' target="_blank" rel="noreferrer noopener" className="error-button">Install {name}</a>
    </div>
  )
}

