import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import carousel from '../../assets/carousel_image.png'
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import "./Carousel.css";

// import required modules
import SwiperCore, { EffectCoverflow, Pagination, FreeMode, Autoplay } from "swiper";

SwiperCore.use([Autoplay])
export default function Carousel({nfts}) {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: 500,
          modifier: 1,
          slideShadows: false,
        }}
        initialSlide={1}
        pagination={true}
        freeMode={true}
        modules={[EffectCoverflow, Pagination, FreeMode, Autoplay]}
        className="mySwiper-mint-page"
      >
        {nfts.map(nft=>(
          <SwiperSlide>
            <img src={nft} alt='card' />
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>
          <img src={carousel} alt='card' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={carousel} alt='card' />
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}
