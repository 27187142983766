import React, { useEffect, useState, useRef } from "react";
import HomeHeader from "../../components/Landing_Header/Landing_Header";
import "./LandingPage.css";
import "swiper/css/navigation";
import background from "../../assets/background.png";
import { Footer } from "../../components/Footer/Footer";
import { ReactComponent as Qr } from "../../assets/qr.svg";
import centerlogo from "../../assets/center_logo.webp";
//import VideoBackground from '../../assets/video_background.png';
import perk4 from "../../assets/perk4.svg";
import howtobuyImg from "../../assets/howtobuy.png";
// import howtobuyBgImg from "../../assets/howtobuy.svg";
import perk7 from "../../assets/pokerset.png";
import perk6 from "../../assets/perk6.svg";
import { MintPerk } from "../../components/Mint_Perks/MintPerk";
import ReactPlayer from "react-player";
import chartImg from "../../assets/Pie 3.png";
import bgEllipse from "../../assets/ellipse.svg";
import upIcon from "../../assets/upIcon.svg";
// import downIcon from "../../assets/downIcon.svg";
import openLink from "../../assets/openLink.svg";
import stakingImg from "../../assets/My project (3).webp";
import nftCards1 from "../../assets/movingCards1.webp";
import nftCards2 from "../../assets/movingCards2.webp";
import dealereImg from "../../assets/LEAD DEVELOPER.jpg"
import investorImg from '../../assets/INVESTOR.jpg'
import artistImg from '../../assets/ARTIST.jpg'
import curatorImg from '../../assets/GAME CURATOR.jpg'
import mediaImg from '../../assets/SOCIAL MEDIA .jpg'
import writerImg from '../../assets/content writer.jpg'
import managerImg from '../../assets/COMMUNITY MANAGER.jpg'
import marketHeadImg from '../../assets/MARKETING LEAD.jpg'
import telegramImg from "../../assets/telegram.svg";
import telegramImg2 from "../../assets/telegram2.svg";
import arrowDown from "../../assets/arrowDown.svg";
import arrowUp from "../../assets/arrowUp.svg";
import twitterImg from "../../assets/twitter.svg";
import instagramImg from "../../assets/instagram.svg";
import Roadmap from "../../components/RoadMap/RoadMap";
import letter from "../../assets/images/letter.png";
import gears from "../../assets/images/gears.png";
import shirtImg from "../../assets/merch/newshirt1.webp";
import shoesImg from "../../assets/merch/newshoes 1.webp";
import vid from "../../assets/EXPLAINER VIDEO WEBSITE.mp4";
import card1 from "../../assets/images/card1.png";
import Merch from "../../components/Merch/Merch";
import TokenInfo from "../../components/TokenInfo/TokenInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import whitepaper from '../../assets/files/TGK WHITEPAPER .pdf'
import audits from '../../assets/files/The Gamble Kingdom Smart Contract Audit Report - QuillAudits.pdf'
import guidlines from '../../assets/files/TGK GAMING AND GUIDELINES .pdf'
import FireFlies from "../../components/FireFlies/FireFlies";
import pokersetImg from '../../assets/merch/new pokerset 3.webp'
import glassesImg from '../../assets/merch/lv glasses 1.webp'
import jacketImg from '../../assets/merch/new jacket2.webp'

const phases = [
  {
    heading: "Phase 1",
    text: [
      `•	$TGK Token Launch `,
      `•	$TGK contract address renounced and liquidity locked`,
      `•	$TGK listings on CoinMarketCap, CoinGecko, Crypto.com`,
      `•	$TGK listings on CoinMarketCap, CoinGecko`,
      `•	TGK BETA game to begin`,
      `•	Regular shilling and buy contests `,
      `•	Influencer collaborations `,
      `•	100 holders target `,
    ],
  },
  {
    heading: "Phase 2",
    text: [
      `•	TGK Gambling game to go live `,
      `•	Onboard and integrate another token onto our platform`,
      `•	Production of original TGK merchandise such as T-shirts, cards, poker sets etc`,
      `•  TGK Game v2 version testing begun `,
      `•	500 holders target`,
    ],
  },
  {
    heading: "Phase 3",
    text: [
      `•	TGK Game v2 version deployment  `,
      `•	1000 holders target `,
      `•	TGK staking platform deployment`,
      `•	Onboarding of other projects through collaborations`,
    ],
  },
  {
    heading: "Phase 4",
    text: [
      `•	Bridge to Polygon Chain for gaming adoption and efficient on-chain transactions with zero cost and zero latency`,
      `•	TGK meta verse casino `,
      `•	Organize celebrity/influencer gaming tournaments that will be live streamed`,
    ],
  },
];
const navList = [
  {
    name: "Tax",
    type: "nav",
  },
  {
    name: "Supply",
    type: "nav",
  },
  // {
  //   name: "Whitepaper",
  //   type: "link",
  //   uri: whitepaper,
  // }
];
const elements = [
  
  {
    title: "",
    image: card1,
    text: "The Gamble Kingdom believes in having 0% TAX for it’s members on any and all transactions. ",
  },
];

const supplies = [
  { name: "Liquidity Pool", percent: 86.9},
  { name: "Marketing", percent: 5 },
  { name: "team", percent: 3.1 },
  { name: "Exchange", percent: 3.1 },
];
const merch = [
  { id: 1, name: "Shirt", price: "0", image: shirtImg },
  { id: 2, name: "Shoes", price: "0", image: shoesImg },
  { id: 3, name: "Pokerset", price: "0", image: pokersetImg },
  { id: 3, name: "Glasses", price: "0", image: glassesImg },
  { id: 3, name: "Jacket", price: "0", image: jacketImg },
];

export const LandingPage = () => {
  const [activeStep, setActiveStep] = useState("metamask");
  const [activeMap, setActiveMap] = useState(0);
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [nftCardsStyles, setNftCardsStyles] = useState('')

  const navigate = useNavigate()

  const howToBuyRef = useRef(null)
  const faqRef = useRef(null)
  const teamRef = useRef(null)
  const nftRef = useRef(null)
  const stakingRef = useRef(null)
  const featuresRef = useRef(null)

  const handleScroll = () => {
    console.log("Scroll");

    const { innerHeight } = window
    const { scrollTop } = document.body
    const { offsetTop, offsetHeight } = nftRef.current
    console.log(nftRef)
    
    // const nftCollectionElement = document.querySelector('.nft-collection')
    // console.log(nftCollectionElement.offsetHeight)
    // console.log(innerHeight * 2)
    // console.log(innerHeight * 3)
    // console.log(innerHeight * 4)
    // console.log(scrollTop) 
    if(scrollTop < 2400 + innerHeight){
      setActiveStep('metamask')
    } else if(2400  + innerHeight <= scrollTop && scrollTop < 2400 + 2 * innerHeight){
      setActiveStep('sendEth')
    } else if(2400 + 2 * innerHeight <= scrollTop && scrollTop < 2400 + 3 * innerHeight){
      setActiveStep('connectWallet')
    }else {
      setActiveStep('swap')
    } 
    console.log("scrollTop", scrollTop)
    console.log("innerHeight",innerHeight)
  
    if(scrollTop < 6000 + innerHeight){
      setActiveMap(0)
    } else if(6000  + innerHeight <= scrollTop && scrollTop < 6000 + 2 * innerHeight){
      setActiveMap(1)
    } else if(6000 + 2 * innerHeight <= scrollTop && scrollTop < 6000 + 3 * innerHeight){
      setActiveMap(2)
    }else {
      setActiveMap(3)
    }
    if(scrollTop >= (offsetTop - 378) && scrollTop <= (offsetTop + offsetHeight )){
      // console.log(Math.floor(scrollTop-11090))
      const finalCalc = Math.floor((scrollTop-11090)/8)
      setNftCardsStyles(finalCalc)
    }
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  
    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  useEffect(()=>{
    console.log('helllllllllllloye')
  },[nftRef])
  return (
    <div className="landing-page-container">
      <HomeHeader />
      <div className="image-div">
        <img src={background} alt="back" className="bg-image" />
      </div>
      <div className="landing-page-content">
        <div className="landing-page-top">
          <div className="page-head">
            <div className="landing-page-top-heading main-heading">THE GAMBLE KINGDOM</div>
            <div className="landing-page-top-content">
              <p className="sub-heading">
              A revolutionary gambling token with a one of a kind gaming experience.
              </p>
            </div>
            <div className="landing-page-top-buttons">
              <button onClick={()=>navigate('/dex')} className="btn">Buy Token</button>
              <button className="btn-outline" onClick={() => window.open('https://t.me/thegamblekingdomofficial')}>
                <Qr id="qr" />
                Join The Community
              </button>
            </div>
            <div className="center-logo-pg">
              <img src={centerlogo} id="center-logo" alt="center" />
            </div>
          </div>

          <div className="overlap-div">
            <div className="h1-video">
              <div className="h1-video-background">
                <div className="h1-video-container">
                  
                <div>
                    <div className="h1-video-heading main-heading">
                      The world of gamble kingdom is no less than an adventure
                    </div>
                    <div className="h1-video-subheading sub-heading">
                      Get a glimpse of what all we have in store for you and
                      let’s get you started on the journey of creating history.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={featuresRef} className="main-features">
            <FireFlies reference={featuresRef} />
            <div className="main-features-heading main-heading">THE GAMBLE KINGDOM IS FULL OF SURPRISES</div>
            <div className="main-features-content">
              <MintPerk
                src={perk7}
                heading={""}
                subheading={
                  <strong>Game and gamble with players from across the globe</strong>
                }
              />
              <MintPerk
                src={perk4}
                heading={""}
                subheading={
                  <strong> Gamble and Increase your bag</strong>
                }
              />
              <MintPerk
                src={perk6}
                heading={""}
                subheading={
                  <strong>Become part of a thriving crypto community</strong>
                }
              />
            </div>
          </div>
          <div className="how-to-buy" ref={howToBuyRef}>
            <div className="container">
              <div className="setps-to-buy ">
                <div className="title main-heading">How To Buy</div>
                <div className="steps">
                  <div
                    className={`step ${
                      activeStep === "metamask" && "step-active"
                    }`}
                    onClick={() => setActiveStep("metamask")}
                  >
                    Create MetaMask wallet
                    <div
                      className={`sub-step ${
                        activeStep === "metamask" && "sub-step-active"
                      }`}
                    >
                      Create a MetaMask Wallet using either a desktop computer
                      or an iOS/Android mobile device. That will allow you to
                      buy, sell, send, and receive $TGK
                    </div>
                  </div>
                  <div
                    className={`step ${
                      activeStep === "sendEth" && "step-active"
                    }`}
                    onClick={() => setActiveStep("sendEth")}
                  >
                    Send ETH to your wallet
                    <div
                      className={`sub-step ${
                        activeStep === "sendEth" && "sub-step-active"
                      }`}
                    >
                      You can buy Ethereum (ETH) directly on MetaMask or
                      transfer it to your MetaMak wallet from exchanges Like
                      Coinbase, Binance, etc.
                    </div>
                  </div>
                  <div
                    className={`step ${
                      activeStep === "connectWallet" && "step-active"
                    }`}
                    onClick={() => setActiveStep("connectWallet")}
                  >
                    Connect your wallet
                    <div
                      className={`sub-step ${
                        activeStep === "connectWallet" && "sub-step-active"
                      }`}
                    >
                      Access your wallet by clicking “Connect to a wallet” and
                      selecting MetaMask.
                    </div>
                  </div>
                  <div
                    className={`step ${activeStep === "swap" && "step-active"}`}
                    onClick={() => setActiveStep("swap")}
                  >
                    Swap ETH for $TGK
                    <div
                      className={`sub-step ${
                        activeStep === "swap" && "sub-step-active"
                      }`}
                    >
                      You can swap on Uniswap as
                      soon as you have ETH available! Press ‘Select a token’ and
                      enter the token address or search for it on the tokens
                      list.
                    </div>
                  </div>
                </div>
                <div>
                  <button onClick={() => window.open('https://app.uniswap.org/#/tokens/ethereum/0xc4b1c504658f2d8105AE84e8e0504d77B629AD8c')} className="btn">Buy $TGK</button>
                </div>
              </div>
              <div className="img-col">
                <div className="img-box">
                  <img src={howtobuyImg} alt="howtobuy" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="reward-allocation">
            <div className="heading">
              <div className="landing-page-top-heading main-heading">Reward Allocation</div>
              <div className="landing-page-top-content sub-heading">
                <p>
                  Rewards in the gamble kingdom have been distributed and
                  categorized across tiers in order to enhance the competitive
                  spirit of the game and give each player a fair chance at
                  winning.
                </p>
              </div>
            </div>
            <div className="body">
              <div className="img-box">
                <img src={bgEllipse} alt="bgellipse" className="bg-ellipse" />
                <img src={chartImg} alt="pie" />
              </div>
              <div className="rewards mobileHid" style={{ height: "600px" }}>
                <div className="img-box-1">
                  <img src={upIcon} alt="up" />
                </div>
                <Swiper
                  spaceBetween={50}
                  slidesPerView={4}
                  navigation={{
                    prevEl: '.img-box-1-left',
                    nextEl: '.img-box-1-right'
                  }}
                  observer={true}
                  observeParents={true}
                  parallax={true}
                  direction={"vertical"}
                  className="reward-swiper"
                  style={{ height: "600px" }}
                  modules={[Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Royal Flush</span>
                      <span>$125,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Straight Flush</span>
                      <span>$100,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Four of a Kind</span>
                      <span>$90,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Full House</span>
                      <span>$70,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-4">
                      <span>Flush</span>
                      <span>$50,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Straight</span>
                      <span>$40,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Three of a kind </span>
                      <span>$25,000</span>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="img-box-1">
                  <img src={upIcon} alt="up" />
                </div>
              </div>
              <div className="rewardsRow mobileVis">
                <div className="img-box-1-left">
                  <img src={upIcon} alt="up" />
                </div>
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={{
                    prevEl: '.img-box-1-left',
                    nextEl: '.img-box-1-right'
                  }}
                  observer={true}
                  observeParents={true}
                  parallax={true}
                  // direction={"vertical"}
                  className="reward-swiper-row"
                  // style={{ height: "600px" }}
                  modules={[Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Royal Flush</span>
                      <span>$125,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Straight Flush</span>
                      <span>$100,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Four of a Kind</span>
                      <span>$90,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Full House</span>
                      <span>$70,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-4">
                      <span>Flush</span>
                      <span>$50,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Straight</span>
                      <span>$40,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Three of a kind </span>
                      <span>$25,000</span>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="img-box-1-right">
                  <img src={upIcon} alt="up" />
                </div>
              </div>

            </div>
            <div className="button-box">
              <a href={guidlines} download='TGK Gaming Guidelines' className="btn-outline">
              Gaming Guidelines <img src={openLink} alt="open link" />{" "}
              </a>
            </div>
          </div> */}
          {/* <div ref={stakingRef} className="staking">
            <FireFlies reference={stakingRef} />
            <div className="img-box">
              <img src={stakingImg} alt="staking" />
            </div>
            <div className="staking-body">
              <div className="h1-video-heading main-heading">Staking $TGK</div>
              <div className="h1-video-subheading sub-heading">
                The gamble kingdom values it’s long term holders by offering a
                staking platform through it’s very own dApp.
                <br />
                <br />A $TGK holder will be eligible for free NFT airdrops,
                passive and static rewards via a 275% APY and much more.
              </div>
              <div>
                <button onClick={()=>navigate('/stake')} className="btn">Stake $TGK</button>
              </div>
            </div>
          </div> */}
          <div className="roadmap">
            <Roadmap phases={phases} activeMap={activeMap} setActiveMap={setActiveMap} />
          </div>
          <TokenInfo
            navList={navList}
            elements={elements}
            supplies={supplies}
          />
          <div ref={nftRef} className="nft-collection">
          <FireFlies reference={nftRef} />
              
              
              
            </div>
            <div ref={nftRef} className="nft-collection">
          <FireFlies reference={nftRef} />
            <div className="heading">
              <div className="landing-page-top-heading main-heading">THE GAMBLE KINGDOM DECK</div>
              <div className="landing-page-top-content sub-heading">
               
              </div>
              {/* <div className="btn-box">
                <button onClick={()=> navigate('/mint')} className="btn">Mint NFT</button>
              </div> */}
            </div>
            <div className="nfts">
              <div className="card-box card-box-1">
                <img id="nft-card-1" src={nftCards1} alt="nftCard" style={{
                  objectPosition: (nftCardsStyles-200) +'px center'
                }}/>
              </div>
              <div className="card-box card-box-1">
                <img id="nft-card-2" src={nftCards2} alt="nftCard" style={{
                  objectPosition: (-nftCardsStyles-50) +'px center'
                  // transform: 'translateX(-'+ nftCardsStyles +'px)'
                }}/>
              </div>
            </div>
          </div>
          
          <Merch merch={merch} />
          <div className="heading">
            <div className="landing-page-top-heading main-heading">Team Members</div>
          </div>
          <div ref={teamRef} className="team-members">
          <FireFlies reference={teamRef} />
            <div>
              <div className="img-box">
                <img src={dealereImg} alt="team1" />
              </div>
              <div>
                <span>Dealer</span>
                <span>Lead Developer</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={investorImg} alt="team1" />
              </div>
              <div>
                <span>Ace of Spades</span>
                <span>Investor</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={artistImg} alt="team1" />
              </div>
              <div>
                <span>King of Diamonds</span>
                <span>Lead Artist</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={curatorImg} alt="team1" />
              </div>
              <div>
                <span>Gaming Professor</span>
                <span>Game Curator</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={mediaImg} alt="team1" />
              </div>
              <div>
                <span>Queen of Hearts</span>
                <span>Social Media Manager</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={writerImg} alt="team1" />
              </div>
              <div>
                <span>Jack of All Trades</span>
                <span>Content writer</span>
              </div>
            </div>
            <div>
              <div className="img-box">
                <img src={managerImg} alt="team1" />
              </div>
              <div>
                <span>Joker</span>
                <span>Community Manager</span>
              </div>
            </div>

            <div>
              <div className="img-box">
                <img src={marketHeadImg} alt="team1" />
              </div>
              <div>
                <span>Flush King</span>
                <span>Marketing Lead</span>
              </div>
            </div>
          </div>
          <div className="join-us">
            <a href="https://t.me/thegamblekingdomofficial" className="btn-outline">
              <img src={telegramImg} alt="telegram" /> Join Us On Telegram
            </a>
          </div>
          <div className="faq">
            <FireFlies reference={faqRef} />
            <div className="head main-heading">FAQ'S</div>
            <div className="body">
              <div className="faq-box">
                <div
                  onClick={() => setFaq1((prevVal) => !prevVal)}
                  className="question"
                >
                  <span>What is The Gamble Kingdom?</span>
                  {faq1 ? (
                    <img src={arrowUp} alt="close" />
                  ) : (
                    <img src={arrowDown} alt="open" />
                  )}
                </div>
                {faq1 && (
                  <div className="answer">
                    A gaming token created with a vision of revolutionizing the gaming and crypto space by combining the two and bringing about an experience like never before.
                  </div>
                )}
              </div>
              <div className="faq-box">
                <div
                  onClick={() => setFaq2((prevVal) => !prevVal)}
                  className="question"
                >
                  <span>Why should one invest?</span>
                  {faq2 ? (
                    <img src={arrowUp} alt="close" />
                  ) : (
                    <img src={arrowDown} alt="open" />
                  )}
                </div>
                {faq2 && (
                  <div className="answer">
                    TGK is bringing back the gaming token era with it’s own amplified version, with various futuristic approaches, one of a kind gaming experience and reward claiming opportunities all curated by an experienced team of professionals.
                  </div>
                )}
              </div>
              <div className="faq-box">
                <div
                  onClick={() => setFaq3((prevVal) => !prevVal)}
                  className="question"
                >
                  <span>
                  When will the TGK gaming sessions be held?
                  </span>
                  {faq3 ? (
                    <img src={arrowUp} alt="close" />
                  ) : (
                    <img src={arrowDown} alt="open" />
                  )}
                </div>
                {faq3 && (
                  <div className="answer">
                    The TGK team has a lot in store for all its members; we will be announcing each gaming session in time. Watch out for our social media handles, the surprises will drop anytime soon. 
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="join-community-footer">
            <div className="landing-page-top-heading main-heading">Join The Community</div>
            <div className="landing-page-top-content">
              <p className="sub-heading">
                Become a member of a thriving community of crypto gaming
                enthusiasts from all across the globe.
              </p>
            </div>
            <div className="landing-page-top-buttons footer">
              <a href="https://t.me/thegamblekingdomofficial" className="btn">
                <img src={telegramImg2} alt="telegram" /> Join Us On Telegram
              </a>
              <div className="btn-boxx">
                <a href="https://twitter.com/gamblekingdom" className="btn-outline-icon">
                  <img src={twitterImg} alt="twitter" />
                </a>
                <a href="https://www.instagram.com/thegamblekingdom/" className="btn-outline-icon">
                  <img src={instagramImg} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
