import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import footerLogo from '../../assets/TGK_Logo_Gold-Texture_2.webp'
import { Follow } from '../Follow_Card/Follow'
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Telegram} from '../../assets/telegram.svg';
import {ReactComponent as OpenSea} from '../../assets/opensea.svg';
import whitepaper from '../../assets/files/TGK WHITEPAPER .pdf'
import audits from '../../assets/files/The Gamble Kingdom Smart Contract Audit Report - QuillAudits.pdf'
import guidlines from '../../assets/files/TGK GAMING AND GUIDELINES .pdf'

export const Footer = () => {
  return (
    <div className="footer-container">
        <div className="footer-container-top">
            <div className="footer-logo">
                <img alt='' src={footerLogo} className='footer-logo'/>
            </div>
            {/* <div className="footer-stake">
                <h1>Stake</h1>
                <div className='foot-files'>
                    <Link to={'/stake'}>Stake</Link>
                    <Link to={'/stake'}>Unstake</Link>
                </div>
            </div> */}
            <div className="footer-swap">
                <h1>Swap</h1>
                <div className='foot-files'>
                    <a href='https://app.uniswap.org/#/tokens/ethereum/0xc4b1c504658f2d8105AE84e8e0504d77B629AD8c'>Buy $TGK</a>
                </div>
            </div>
            <div className="footer-useful-links">
                {/* <h1>Useful Links</h1>
                <div className='foot-files'>
                <a href={whitepaper} download="TGK-whitepaper.pdf">Whitepaper</a>
                <a href={audits} download='QuillHash-audit.pdf'>Audit Report</a>
                </div> */}
            </div>
            <div className="footer-find-us">
                <h2>Find us on</h2>
                <div className="footer-find-us-logos">
                    <Follow uri={'https://twitter.com/gamblekingdom'} Src={Twitter}/>
                    <Follow uri={'https://t.me/thegamblekingdomofficial'} Src={Instagram}/>
                    <Follow uri={'https://t.me/thegamblekingdom'} Src={Telegram}/>
                    {/* <Follow uri={'#'} Src={OpenSea}/> */}
                </div>
            </div>
            <div className="footer-container-copyright">
            <h4>Copyright © 2022. All rights reserved by The Gamble Kingdom</h4>
        </div>
        </div>
    </div>
  )
}
