import React from "react";
import "./Landing_Header.css";
import logo from "../../assets/logo.webp";
import { Follow } from '../Follow_Card/Follow'
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Telegram} from '../../assets/telegram.svg';
import auditImg from '../../assets/images/QuillAudits White.png'
import audits from '../../assets/files/The Gamble Kingdom Smart Contract Audit Report - QuillAudits.pdf'
import { useNavigate } from "react-router-dom";
import mobileLogo from '../../assets/TGK_Logo_Gold-Texture_2.webp'

function HomeHeader() {
  const navigate = useNavigate()
  return (
    <div className="home-header">
      <div className="home-logo">
        <img src={logo} alt='' className="home-header-logo pc" />
        <img src={mobileLogo} alt='' className="home-header-logo mobile" />
      </div>
      <div className="home-header-button">
        <button onClick={()=>navigate('/dex')} className="home-header-menu">Buy Token</button>
        {/* <button onClick={()=>navigate('/stake')} className="home-header-menu-o mobileHid">Stake</button> */}
        <div className="footer-find-us-logos head mobileHid">
          <Follow uri={"https://twitter.com/gamblekingdom"} Src={Twitter} />
          <Follow
            uri={"https://www.instagram.com/thegamblekingdom/"}
            Src={Instagram}
          />
          <Follow uri={"https://t.me/thegamblekingdomofficial"} Src={Telegram} />
        </div>
        {/* <a href={audits} download='QuillHash-audits.pdf' className=" mobileHid">
          <img className="quillhash-audit" src={auditImg} alt="audit" />
        </a> */}
      </div>
    </div>
  );
}

export default HomeHeader;
