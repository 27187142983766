import React from 'react';
import './Coin.css';

function Coin({src,name, disabled, state, setState}){

    return(
        <div className="coin-container">
            <div className="coin-container-left">
                <input disabled={disabled} onChange={(e)=>setState(e.target.value)} type="number" placeholder='0.00' value={state} />
            </div>
            <div className="coin-container-right">
                <img src={src}  alt="img"/>
                <span className="coin-container-right-name">
                    {name}
                </span>
            </div>
        </div>
    )
}

export default Coin;