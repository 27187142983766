import React, { useState } from 'react';
import {ReactComponent as Close} from '../../../assets/up_arrow.svg'
import {ReactComponent as Open} from '../../../assets/down_arrow.svg'


const MintAccordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="mint-accordion-item">
      <div className="mint-accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? <Close/> : <Open/>}</div>
      </div>
      {isActive && <div className="mint-accordion-content">{content}</div>}
    </div>
  );
};

export default MintAccordion;