import React, { useEffect, useState } from 'react'
import { getAmountInEther, getAmountInWai, getAvailableToStake, getStakeAmount,getTGKValue,stake } from '../../utils/web3Services'
import './Stake.css'
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import {toast} from 'react-toastify';

export const StakeBox = () => {
    const [amountToStake,setAmoutToStake] = useState(0)
    const [amountToShow,setAmountToShow] = useState("0.00")
    const [available,setAvailable] = useState("0.00")
    const [loading,setLoading] = useState(false);
    const [level,setLevel] = useState(0);

    const setToStake = async (level) =>{
        try {
            const amount = await getStakeAmount(level)
            console.log("amount ",amount)
            setLevel(level);
            const tgkAmout = await getTGKValue(amount)
            setAmoutToStake(tgkAmout)
            const toShow = await getAmountInEther(tgkAmout)
            setAmountToShow(Number(toShow).toFixed(2))
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }

    const handleInput = async (value) =>{
        try {
            const amount = await getStakeAmount("classic")
            const tgkAmout = await getTGKValue(amount)
            const toShow = await getAmountInEther(tgkAmout)
            if(value < toShow) {
                setAmountToShow(Number(toShow).toFixed(2))
                return toast.error(`Value should not be less than ${Number(toShow).toFixed(2)}`)
            }
            const in_wei = await getAmountInWai(value)
            setAmoutToStake(in_wei)
            setAmountToShow(value)
        }
        catch(err) {
            console.log(err)
            toast.error(err.response || "Error !")
        }
    }
    const set_amount_to_show = async () => {
        try {
            const amount = await getStakeAmount("classic")
            const tgkAmout = await getTGKValue(amount)
            const toShow = await getAmountInEther(tgkAmout)
            setAmountToShow(Number(toShow).toFixed(2))
            setAmoutToStake(tgkAmout)
        }
        catch(err) {
            console.log(err);
        }
    }
    const getAvailableBalance = async() =>{
        const amount = await getAvailableToStake()
        setAvailable(amount)
    }
    const handleStake = async ()=>{
        try {
            setLoading(true)
            console.log("amountToStake",amountToStake)
            await stake(amountToStake)
            setLoading(false);
        }
        catch(err) {
            console.log(err);
            setLoading(false);
        }
    }
    
    useEffect(()=>{
        getAvailableBalance()
        set_amount_to_show()
    },[])
    
  return (
    <div className="stake-box-container">
        <div className="stake-box-heading">
            Stake
        </div>
        <div className="container2">
            <div className="container2-heading1">
                Amount
                <input type="number" placeholder='11111111'  value={amountToShow} onChange={(e)=>handleInput(e.target.value)}></input>
            </div>
            <div className="container2-heading2">
                TGK Token
                <div className="b44">
                    <div className='b-classic cursor-pointer'>
                        <button className="classic" onClick={()=> setToStake("classic")}>Classic</button>
                    </div>
                    <div className='b-silver cursor-pointer' >
                        <button className="silver" onClick={()=> setToStake("silver")}>Silver</button>
                    </div>
                    <div className='b-gold cursor-pointer'>
                        <button className="gold" onClick={()=> setToStake("gold")}>Gold</button>
                    </div>
                    <div className='b-diamond cursor-pointer'>
                        <button className="diamond" onClick={()=> setToStake("diamond")}>Diamond</button>
                    </div>
                </div>
            </div>
        </div> 
        <div className="stake-box-rewards">
            Available to Stake: {Number(available).toFixed(2)} 
        </div>
        <button className='stake-token' onClick={handleStake}>Stake Tokens <ButtonLoading loading={loading} color={"black"}/></button>
    </div>
  )
}
