// Background
import bg2 from "../../assets/svgs/2.webp";
import bg3 from "../../assets/svgs/3.webp";
import ellipse from "../../assets/svgs/bgEllipseRoadmap.svg";
import diamondSpecial from "../../assets/svgs/A_Diamond_Special_GoldF.svg";

import bg2P1 from "../../assets/images/Mask group-5.png";
import bg3P1 from "../../assets/images/Mask group-4.png";
import diamondSpecialP1 from "../../assets/images/Mask group-2.png";

import bg2P2 from "../../assets/images/Mask group-7.png";
import bg3P2 from "../../assets/images/Mask group-11.png";
import diamondSpecialP2 from "../../assets/images/Mask group-7.png";

import bg2P3 from "../../assets/images/Mask group-12.png";
import bg3P3 from "../../assets/images/Mask group-15.png";
import diamondSpecialP3 from "../../assets/images/Mask group-12.png";

const RoadMapBackground = ({ phase }) => {
  return (
    <>
          <img
            src={ellipse}
            alt="background ellipse"
            className="background-particle"
          />
          <img
            src={ellipse}
            alt="background ellipse"
            className="background-particle"
          />
          <img
            src={ellipse}
            alt="background ellipse"
            className="background-particle"
          />
          <img
            src={ellipse}
            alt="background ellipse"
            className="background-particle"
          />
      {phase === 0 && (
        <>

          <img
            src={bg2}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecial}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecial}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={bg3}
            alt="background particle"
            className="background-particle"
          />
        </>
      )}
      {phase === 1 && (
        <>
          <img
            src={bg2P1}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP1}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP1}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={bg3P1}
            alt="background particle"
            className="background-particle"
          />
        </>
      )}
      {phase === 2 && (
        <>
          <img
            src={bg2P2}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP2}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP2}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={bg3P2}
            alt="background particle"
            className="background-particle"
          />
        </>
      )}
      {phase === 3 && (
        <>
          <img
            src={bg2P3}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP3}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={diamondSpecialP3}
            alt="background particle"
            className="background-particle"
          />
          <img
            src={bg3P3}
            alt="background particle"
            className="background-particle"
          />
        </>
      )}
    </>
  );
};

export default RoadMapBackground;
