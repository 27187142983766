import React from 'react'
import './Portfolio.css'

export const Portfolio = ({stakedValue,totalRewards}) => {
  return (
    <div className="portfolio-container">
        <div className="portfolio-heading">
            User Portfolio
        </div>
        <div className="portfolio-container-content">
            <div className="total-value">
                <p>Total Staked Value</p>
                <p>{stakedValue}</p>
            </div>
            <div className="average">
                <p>Average APY</p>
                <p>275</p>
            </div>
            <div className="rewards-unclaimed">
                <p>Total Rewards (unclaimed)</p>
                <p>{totalRewards}</p>
            </div>
        </div>
    </div>
  )
}
