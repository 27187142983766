import React, { useContext, useEffect, useState } from "react";
import "./Swap.css";
import { ReactComponent as SvgSetting } from "../../assets/setting.svg";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import Coin from "../Coin/Coin";
import eth from "../../assets/eth.png";
import tkg from "../../assets/tgkToken.png";
import tether from "../../assets/tether-usdt.svg";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Setting } from "../Transaction_Setting/Setting";
import ConnectWallet from "../Connect_Wallet/Connect_Wallet";
import close from "../../assets/close.png";
import { Web3Context } from "../../context/Web3Context";
import { tgkTokenAddress, USDTAddress, WEthAddress } from "../../utils/config";
import {
    getAmountOut,
    getAmountInEther,
    swapExactTGKForEth,
    swapExactEthForTGK,
    swapExactTokensForTGK,
    swapExactTGKForTokens,
    getBalanceOfEtherWallet,
    getBalanceOfUsdt,
    getAvailableToStake,
} from "../../utils/web3Services";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import CoinDropdown from "../Coin/CoinDropdown";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        backdropFilter: "blur(15px)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
    paper: {
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
    },
}));

const coinList = ["eth", "usdt"];

function Swap() {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [coinListValue, setCoinListValue] = useState("eth");
    const [inputValue, setInuptValue] = useState(0);
    const [tgkVal, setTgkVal] = useState(0);
    const [swap, setSwap] = useState("eth");
    const [slippage, setSlippage] = useState(1);
    const [txDuration, setTxDuration] = useState(5);
    const [amountOut, setAmoutOut] = useState(0);
    const [balanceA, setBalanceA] = useState(0.0);
    const [balance_tgk, setBalance_tgk] = useState(0.0);
    const [expertMode,setExpertMode] = useState(false);
    const { isWalletConnected } = useContext(Web3Context);

    const changePrice = async (value) => {
        try {
            let amountOut;
            if (swap == "tgk" && coinListValue == "eth") {
                amountOut = await getAmountOut(tgkVal, [
                    tgkTokenAddress,
                    WEthAddress,
                ]);
                setAmoutOut(amountOut[1]);
                setInuptValue(await getAmountInEther(amountOut[1]));
            } else if (coinListValue == "eth" && swap == "eth") {
                amountOut = await getAmountOut(inputValue, [
                    WEthAddress,
                    tgkTokenAddress,
                ]);
                setAmoutOut(amountOut[1]);
                setTgkVal(await getAmountInEther(amountOut[1]));
            } else if (coinListValue == "usdt" && swap == "eth") {
                console.log(inputValue);

                amountOut = await getAmountOut(
                    inputValue,
                    [USDTAddress, tgkTokenAddress],
                    true
                );
                console.log(amountOut);
                setAmoutOut(amountOut[1]);
                setTgkVal(await getAmountInEther(amountOut[1]));
            } else if (swap == "tgk" && coinListValue == "usdt") {
                amountOut = await getAmountOut(tgkVal, [
                    tgkTokenAddress,
                    USDTAddress,
                ]);
                setAmoutOut(amountOut[1]);
                setInuptValue(await getAmountInEther(amountOut[1]));
            }
        } catch (error) {
            console.log(error, "error");
        }
    };
    const getEtherBalance = async () => {
        try {
            const amount = await getBalanceOfEtherWallet();
            setBalanceA(amount);
        } catch (err) {
            console.log(err);
        }
    };
    const getUsdtBalance = async () => {
        try {
            const amount = await getBalanceOfUsdt();
            setBalanceA(amount);
        } catch (err) {
            console.log("err ", err);
        }
    };
    const getTgkBalance = async () => {
        try {
            const amount = await getAvailableToStake();
            setBalance_tgk(Number(amount).toFixed(2));
        } catch (err) {
            console.log("err ", err);
        }
    };
    useEffect(() => {
        getEtherBalance();
        getTgkBalance()
    }, []);
    useEffect(() => {
        getEtherBalance();
        getTgkBalance()
    },[isWalletConnected])
    useEffect(() => {
        changePrice();
        if (coinListValue == "eth") {
            getEtherBalance();
        } else {
            getUsdtBalance();
        }
    }, [inputValue, tgkVal, coinListValue, swap]);

    const formateMin = (amount, slippage) => {
        console.log(amount, "amount");
        return Math.round(
            parseFloat(amount) - (parseFloat(amount) / 100) * slippage
        ).toString();
    };

    const swapTokens = async () => {
        try {
            setLoading(true);
            if (swap == "tgk" && coinListValue == "eth") {
                await swapExactTGKForEth(
                    tgkVal,
                    formateMin(amountOut, slippage),
                    txDuration,
                    expertMode
                );

                setLoading(false);
            } else if (coinListValue == "eth" && swap == "eth") {
                await swapExactEthForTGK(
                    inputValue,
                    formateMin(amountOut, slippage),
                    txDuration,
                    expertMode
                );
                setLoading(false);
            } else if (coinListValue == "usdt" && swap == "eth") {
                await swapExactTokensForTGK(
                    inputValue,
                    formateMin(amountOut, slippage),
                    txDuration,
                    true,
                    expertMode
                );
                setLoading(false);
            } else if (swap == "tgk" && coinListValue == "usdt") {
                await swapExactTGKForTokens(
                    tgkVal,
                    formateMin(amountOut, slippage),
                    txDuration,
                    expertMode
                );
                setLoading(false);
            }
        } catch (error) {
            console.log(error, "error");
            setLoading(false);
        }
    };

    const handleSetting = () => {
        setOpen(true);
    };
    const handleSettingClose = () => {
        setOpen(false);
    };
    const handleConnectWallet = () => {
        setOpen1(true);
    };
    const handleConnectWalletClose = () => {
        setOpen1(false);
    };
    useEffect(() => {
        console.log("expertMode ",expertMode);
    },[expertMode])
    return (
        <div className="total-container">
            <div className="swap-container">
                <div className="swap-top">
                    <div className="swap-top-left">Swap*</div>
                    <div className="swap-top-right">
                        <SvgSetting
                            onClick={handleSetting}
                            className="setting"
                        />
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={open}
                            className={classes.root}
                        >
                            <div style={modalStyle} className={classes.paper}>
                                <div className="close">
                                    <img
                                        src={close}
                                        alt="close"
                                        className="close-button"
                                        onClick={handleSettingClose}
                                    />
                                </div>
                                <Setting
                                    slippage={slippage}
                                    setSlippage={setSlippage}
                                    txDuration={txDuration}
                                    setTxDuration={setTxDuration}
                                    expertMode={expertMode}
                                    setExpertMode={setExpertMode}
                                />
                            </div>
                        </Modal>
                    </div>
                </div>

                {swap === "eth" && (
                    <div className="swap-middle">
                        <div className="swap-middle-top">
                            <div className="showBalance">
                                 Balance: {balanceA}
                            </div>

                            <CoinDropdown
                                disabled
                                src={eth}
                                src2={tether}
                                state={inputValue}
                                setState={setInuptValue}
                                coinListValue={coinListValue}
                                setCoinListValue={setCoinListValue}
                            />
                        </div>
                        <div
                            onClick={() => setSwap("tgk")}
                            className="arrow-div"
                        >
                            <Arrow className="arrow" />
                        </div>
                        <div className="swap-middle-bottom">
                            <div className="showBalance">
                                 Balance: {balance_tgk}
                            </div>
                            <Coin
                                disabled
                                name="TKG"
                                src={tkg}
                                state={tgkVal}
                                setState={setTgkVal}
                            />
                        </div>
                    </div>
                )}
                {swap === "tgk" && (
                    <div className="swap-middle">
                        <div className="swap-middle-bottom">
                            <div className="showBalance">
                                 Balance: {balance_tgk}
                            </div>
                            <Coin
                                name="TKG"
                                src={tkg}
                                state={tgkVal}
                                setState={setTgkVal}
                            />
                        </div>

                        <div
                            onClick={() => setSwap("eth")}
                            className="arrow-div"
                        >
                            {" "}
                            <Arrow className="arrow" />
                        </div>
                        <div className="swap-middle-top">
                            <div className="showBalance">
                                 Balance: {balanceA}
                            </div>
                            <CoinDropdown
                                disabled
                                src={eth}
                                src2={tether}
                                state={inputValue}
                                setState={setInuptValue}
                                coinListValue={coinListValue}
                                setCoinListValue={setCoinListValue}
                            />
                        </div>
                    </div>
                )}
                {isWalletConnected ? (
                    <button className="b11" onClick={swapTokens}>
                        <ButtonLoading loading={loading} color="black" />
                        Swap
                    </button>
                ) : (
                    <button className="b11" onClick={handleConnectWallet}>
                        Connect Wallet
                    </button>
                )}
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open1}
                    className={classes.root}
                >
                    <div style={modalStyle} className={classes.paper}>
                        <div className="close">
                            <img
                                src={close}
                                alt="close"
                                className="close-button"
                                onClick={handleConnectWalletClose}
                            />
                        </div>
                        <ConnectWallet
                            keyValue={6}
                            onClose={handleConnectWalletClose}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default Swap;
