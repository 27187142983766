import './App.css';
import { Stake } from './Pages/Stake/Stake';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import HeroSection from './Pages/Swap/HeroSection/HeroSection';
import Mint from './Pages/Mint/Mint'
import { LandingPage } from './Pages/Landing_Page/LandingPage';
import { Web3Provider } from './context/Web3Context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Web3Provider>
      <ToastContainer />
      <div className="App">
        <Router>
          <Routes>
            <Route exact path='/dex' element={<HeroSection/>}></Route>
            <Route exact path='/stake' element={<Stake/>}></Route>
            <Route exact path='/mint' element={<Mint/>}></Route>
            <Route exact path='/' element={<LandingPage/>}></Route>
          </Routes>
        </Router>      
      </div>
    </Web3Provider>
  );
}

export default App;
