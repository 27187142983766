import React, { useState } from 'react'
import './Mint.css'
import {Nft} from '../../components/NFT/Nft';
import background from '../../assets/background.png'
import { MintPerk } from '../../components/Mint_Perks/MintPerk';
import perk4 from '../../assets/perk4.svg';
import perk5 from '../../assets/perk5.svg';
import perk6 from '../../assets/perk6.svg';
import MintFaq from '../../components/Mint_FAQ/MintFaq';
import { MintBox } from '../../components/Mint_Box/MintBox';
import { Footer } from '../../components/Footer/Footer';
import Carousel from '../../components/Carousel/Carousel';
import Header from '../../components/Header/Header';
import nftImg1 from '../../assets/nft-card-1.webp'
import nftImg2 from '../../assets/nft-card-2.webp'
import nftImg3 from '../../assets/nft-card-3.webp'
import nftImg4 from '../../assets/nft-card-4.webp'

export const Mint = () => {
    const [nfts, setNfts] = useState([
        nftImg1,
        nftImg2,
        nftImg3,
        nftImg4,
    ])
  return (
    <div className="mint-container">
        <Header/>
        <div className="image-div"><img src={background} alt="back" className='bg-image'/></div>
        <Nft/>
        <div className="mint-content">
            <div className="mint-top">
                <Carousel nfts={nfts}/>
                <MintBox/>
            </div>
            <div className="mint-middle">
                <div className="mint-middle-heading">
                    Perks
                </div>
                <div className="mint-middle-perks">
                    <MintPerk src={perk4} heading={'Stake NFTs'} subheading={'Create a MetaMask Wallet  to buy, sell, send, and receive $TGK'}/>
                    <MintPerk src={perk5} heading={'Community'} subheading={'Create a MetaMask Wallet  to buy, sell, send, and receive $TGK'}/>
                    <MintPerk src={perk6} heading={'Rewards'} subheading={'Create a MetaMask Wallet  to buy, sell, send, and receive $TGK'}/>
                </div>
            </div>
            <div className="mint-bottom">
                <div className="mint-bottom-heading">
                    FAQ
                </div>
                <div className="mint-bottom-faq">
                    <MintFaq title={"What’s the mandatory staking period to receive the FREE AIRDROPS?"} content={"A member will only be eligible for the Free TGK NFT Airdrop if their respective tokens are staked on the day of the airdrop. As there are 3 round of Airdrops taking place, members have the choice to stake it for all 3 rounds or less, as they would like."}/>
                    <MintFaq title={'For how long is the staking period live?'} content={''}/>
                    <MintFaq title={'After staking how does one receive their TGK Tokens and NFTs?'} content={''}/>
                    <MintFaq title={'For how long is the staking period live?'} content={''}/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Mint;
