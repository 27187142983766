import React, { useContext } from "react";
import "./Connect_Wallet.css";
import WalletName from "./Wallet_Name/Wallet_Name";
import metamask from "../../assets/metamask.png";
import trustwallet from "../../assets/trust_wallet.png";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { WalletError } from "../Wallet_Error/Wallet_Error";
import close from "../../assets/close.png";
import { ReactComponent as Metamask } from "../../assets/Metamask.svg";
import { ReactComponent as TrustWallet } from "../../assets/Trust_Wallet.svg";
import { Web3Context } from "../../context/Web3Context";
import ButtonLoading from "../ButtonLoading/ButtonLoading";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        backdropFilter: "blur(15px)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
    paper: {
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
    },
}));

function ConnectWallet({ keyValue, onClose }) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { connectMetamaskWallet, metamaskAddress } = useContext(Web3Context);
    const handleMetamask = async (e) => {
        setLoading(true);
        const res = await connectMetamaskWallet();
        console.log(res);
        if (res && res.metamask === false) {
            e.stopPropagation();
            // document.querySelector(`.jss${keyValue}`).setAttribute('style','visibility: hidden');
            setOpen(true);
        }
        if (res && res.length) {
            onClose();
        }
        setLoading(false);
    };
    const handleMetamaskClose = (e) => {
        e.stopPropagation();
        document
            .querySelector(`.jss${keyValue}`)
            .removeAttribute("style", "visibility: hidden");
        document
            .querySelector(`.jss${keyValue}`)
            .setAttribute(
                "style",
                "top: 50%; left: 50%; transform: translate(-50%, -50%)"
            );
        setOpen(false);
    };
    const handleTrustWallet = (e) => {
        e.stopPropagation();
        document
            .querySelector(`.jss${keyValue}`)
            .setAttribute("style", "visibility: hidden");
        setOpen1(true);
    };
    const handleTrustWalletClose = (e) => {
        e.stopPropagation();
        document
            .querySelector(`.jss${keyValue}`)
            .removeAttribute("style", "visibility: hidden");
        document
            .querySelector(`.jss${keyValue}`)
            .setAttribute(
                "style",
                "top: 50%; left: 50%; transform: translate(-50%, -50%)"
            );
        setOpen1(false);
    };
    return (
        <div className="connect-wallet-container">
            <div className="connect-wallet-heading ">
                
                Connect Wallet
                <ButtonLoading loading={loading} color={"black"}/>
            </div>
            <div className="conatiner-wallet-name">
                <div className="wallet1" onClick={handleMetamask}>
                    <WalletName name="Metamask" src={metamask} />
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open}
                    >
                        <div style={modalStyle} className={classes.paper}>
                            <div className="close">
                                <img
                                    src={close}
                                    alt="close"
                                    className="close-button"
                                    onClick={handleMetamaskClose}
                                />
                            </div>
                            <WalletError name="Metamask" Src={Metamask} />
                        </div>
                    </Modal>
                </div>
                <div className="wallet2" onClick={handleTrustWallet}>
                    <WalletName name="Trust Wallet" src={trustwallet} />
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open1}
                    >
                        <div style={modalStyle} className={classes.paper}>
                            <div className="close">
                                <img
                                    src={close}
                                    alt="close"
                                    className="close-button"
                                    onClick={handleTrustWalletClose}
                                />
                            </div>
                            <WalletError
                                name="Trust Wallet"
                                Src={TrustWallet}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default ConnectWallet;
