import { useState } from "react";
import "./RoadMap.css";

// Components
import RoadMapBackground from "./RoadMapBackground";

const RoadMapPhase = ({ phase, i, current, setCurrent }) => {
  const extraClass = i === current ? " current" : "";
  const onClick = () => setCurrent(i);
  return (
    <div className={"road-map-phase" + extraClass} onClick={onClick}>
      <h1 className="road-map-phase-heading">{phase.heading}</h1>
      <p className={`road-map-phase-info ${extraClass}`}>{phase.text.map((e, index)=>(
        <div  key={`${e}${index}`}>{e}</div>
      ))}</p>
    </div>
  );
};
const RoadMap = ({ phases, activeMap, setActiveMap }) => {
  // const [current, setCurrent] = useState(0);
  return (
    <section className="road-map">
      <RoadMapBackground phase={activeMap} />
      <div className="road-map-modal">
        <h1 className="road-map-heading">The Gamble Kingdom Roadmap</h1>
        <div className="road-map-phases">
          {phases.map((phase, i) => (
            <RoadMapPhase
              phase={phase}
              key={phase.heading+i}
              i={i}
              current={activeMap}
              setCurrent={setActiveMap}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
