import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./buttonLoading.css"

const ButtonLoading = ({ loading,color }) => {
    return (
        <>
            {loading && (
                <CircularProgress className={color == "gold" ? "goldColor" : "loading"} size={18}/>
            )}
        </>
    );
};

export default ButtonLoading;
