import React, { useEffect } from "react";
import "./Nft.css";
import Card1 from "../../assets/left_card.png";
import Card2 from "../../assets/right_card.png";

export const Nft = () => {
  useEffect(() => {
    const setTime = () => {
      var deadline = new Date("Aug 01, 2022 01:00:00 GMT").getTime();
      var x = setInterval(function () {
        var now = new Date().getTime();
        var t = deadline - now;
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((t % (1000 * 60)) / 1000);
        if (days % 10 === days) {
          days = "0" + days.toString();
        }
        if (hours % 10 === hours) {
          hours = "0" + hours.toString();
        }
        if (minutes % 10 === minutes) {
          minutes = "0" + minutes.toString();
        }
        if (seconds % 10 === seconds) {
          seconds = "0" + seconds.toString();
        }
        document.getElementById("timerDemo").innerHTML =
          days + " : " + hours + " : " + minutes + " : " + seconds;
        if (t < 0) {
          clearInterval(x);
          document.getElementById("timerDemo").innerHTML = "00 : 00 : 00 : 00 ";
        }
      }, 1000);
    };
    // setTime();

    return () => {};
  }, []);

  

  return (
    <div className="nft-container">
      <img src={Card1} alt="card" className="left-card" />
      {/* <div className="countdown">
            <div className="countdown-heading">
                NFTs Dropping In
            </div>
            <div className="timer">
              <p className="countdown-time" id="timerDemo">04 : 11 : 24 : 12</p>
              <p className="time-left"><span className='s1'>DAYS</span><span className='s2'>HOURS</span><span className='s3'>MINUTES</span><span className='s4'>SECONDS</span></p>
            </div>
        </div> */}
      <div className="countdown-dropped">
        <div className="countdown-heading">NFT DROPPED</div>
        <div className="timer">
            Congratulations you have received your TGK NFT! Please have a look
            at it on your Opensea account.
        </div>
      </div>
      <img src={Card2} alt="card" className="right-card" />
    </div>
  );
};
