// Library
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper";

// Image
import ellipse from "../../assets/svgs/bgEllipseRoadmap.svg";
import goldenLeft from "../../assets/svgs/goldenLeft.svg";
import goldenRight from "../../assets/svgs/goldenRight.svg";

// Swiper Styles
import "swiper/css";

// CSS
import "./Merch.css";
import { useEffect, useState } from "react";

const Merch = ({ merch }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const pvv = width < 900 ? 1 : 3;
  useEffect(() =>
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    })
  );
  return (
    <section className="merchandise">
      <img
        src={ellipse}
        alt="background-ellipse"
        className="background-ellipse"
      />
      <div className="merchandise-heading main-heading">
      The Official TGK Merchandise Store
      </div>
      <p className="merchandise-intro sub-heading">
        Get your hands on the hottest collection of the season and show off that
        you’re member of the TGK clan.
      </p>
      <p className="merchandise-coming-soon">COMING SOON</p>
      <Swiper
        modules={[EffectCoverflow, Navigation, Pagination]}
        navigation={{
          prevEl: ".merchandise-button.left",
          nextEl: ".merchandise-button.right",
        }}
        spaceBetween={10}
        slidesPerView={pvv}
        className="merchandise-list"
        loop={true}
        watchSlidesProgress={true}
        centeredSlides={true}
        effect={"coverflow"}
        coverflowEffect={{
          rotate: 0,
          scale: 0.8,
          slideShadows: false,
        }}
      >
        <button className="merchandise-button left">
          <div className="merchandise-button-icon-holder">
            <img src={goldenLeft} alt="Left Arrow" />
          </div>
        </button>
        <div style={{ height: "fit content" }}>
          {merch.map((mr,index) => (
            <SwiperSlide key={mr.id+index}>
              <img src={mr.image} alt={mr.name} className="merchandise-image" />
              <p className="merchandise-name">{mr.name}</p>
              {/* <p className="merchandise-price">${mr.price}</p> */}
            </SwiperSlide>
          ))}
        </div>
        <button className="merchandise-button right">
          <div className="merchandise-button-icon-holder">
            <img src={goldenLeft} alt="Right Arrow" />
          </div>
        </button>
      </Swiper>
    </section>
  );
};

export default Merch;
