import React, { useEffect, useState } from "react";
import {
    getAmountInEther,
    getTotalMinted,
    getTotalPrice,
    mintNft,
} from "../../utils/web3Services";
import "./MintBox.css";
import ButtonLoading from "../../components/ButtonLoading/ButtonLoading";

export const MintBox = () => {
    const [counter, setCounter] = useState(1);
    const [totalPrice, setTotalPrice] = useState("0.00");
    const [totalMinted, setTotalMinted] = useState(0);
    const [loading, setLoading] = useState(false);
    let incrementCounter = () => setCounter(counter + 1);
    let decrementCounter = () => setCounter(counter - 1);
    if (counter <= 1) {
        decrementCounter = () => setCounter(1);
    }
    if (counter >= 5) {
        incrementCounter = () => setCounter(5);
    }

    const mintNfts = async () => {
        try {
            setLoading(true);
            await mintNft(counter);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const fetchTotalPrice = async () => {
        try {
            const total = await getTotalPrice(counter);
            const amountInEther = await getAmountInEther(total);
            setTotalPrice(amountInEther);

            
        }
        catch(err) {
            console.log('err' ,err)
        }
    };

    useEffect(() => {
        fetchTotalPrice();
    }, [counter]);

    const fetchTotalMintedNfts = async () => {
        try {
            const total = await getTotalMinted();
        setTotalMinted(total);
        }
        catch(err) {
            console.log('err ',err)
        }
    };

    useEffect(() => {
        fetchTotalMintedNfts();
    }, []);
    return (
        <div className="mint-box-container">
            <div className="mint-box-top">
                <div className="mint-box-top-left">Total Minted</div>
                <div className="mint-box-top-right">{totalMinted}/5,200</div>
            </div>
            <div className="mint-box-middle-box1">
                <input
                    type="text"
                    placeholder="Quantity (Max 5)"
                    value={counter}
                    onChange={(e) => setCounter(e.target.value)}
                />
                <div className="counter-box">
                    <div className="decrement-sign" onClick={decrementCounter}>
                        -
                    </div>
                    <div className="counter-box-value">{counter}</div>
                    <div className="increment-sign" onClick={incrementCounter}>
                        +
                    </div>
                </div>
            </div>
            <div className="mint-box-middle-box2">
                <input type="text" placeholder="Total" disabled />
                <div className="converted-value">{totalPrice} ETH</div>
            </div>
            <div className="mint-box-button">
                <button className="mint-button" onClick={mintNfts}>
                    Mint <ButtonLoading loading={loading} color={"black"}/>
                </button>
            </div>
        </div>
    );
};
