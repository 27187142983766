import React from 'react';
import Accordion from './MintAccordion/MintAccordion';
import './MintFaq.css'

const MintFaq = ({title,content}) => {
  return (
    <div>
      <div className="mint-accordion">
          <Accordion title={title} content={content} />
      </div>
    </div>
  );
};

export default MintFaq;