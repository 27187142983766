import React from 'react'
import './Perks.css'

export const Perks = ({src,heading,content}) => {
  return (
    <div className="perks-container">
       <div className="perk-image">
            <img src={src} alt="perk" className='p1'/>
       </div>
       <div className="perk-content">
            <div className="perk-heading">
                {heading}
            </div>
            <div className="perk-subheading">
                {content}
            </div>
       </div>
    </div>
  )
}
