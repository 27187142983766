import React from 'react'
import './Graph.css'
import Chart from "../Chart/Chart"

export const Graph = () => {
  return (
    <div className="graph-container">
        <div className="graph-heading">
            Graph
        </div>
        <div className="area-graph">
          <Chart />
        </div>
    </div>
  )
}
