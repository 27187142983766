import React, { useEffect, useState } from "react";
import "./Stake.css";
import { Nft } from "../../components/NFT/Nft";
import { StakeBox } from "../../components/Stake/Stake";
import { Reward } from "../../components/Reward/Reward";
import Faq from "../../components/FAQ/Faq";
import { Perks } from "../../components/Perks/Perks";
import Perk1 from "../../assets/perk1.png";
import Perk2 from "../../assets/perk2.png";
import Perk3 from "../../assets/perk3.png";
import { Portfolio } from "../../components/Portfolio/Portfolio";
import { Generic } from "../../components/Generic/Generic";
import { Graph } from "../../components/Graph/Graph";
import { Unstake } from "../../components/Unstake/Unstake";
import background from "../../assets/background.png";
import Header from "../../components/Header/Header";
import {totalStaked,staker,rewarded,totalStakedValue,totalRewardsUnclaimed} from "../../utils/web3Services";

export const Stake = () => {
  const [unstake, setUnstake] = useState(false);

  const [total_staked,setTotalStaked] = useState(0);
  const [rewards,setRewards] = useState(0);
  const [stakedValue,setStakedValue] = useState(0);
  const [totalRewards,setTotalRewards] = useState(0);
  const getGenericPortfolio = async () => {
    try {
      const getStaked = await totalStaked();
      // const arrLen = await staker();
      const rewards = await rewarded();
      const stakedValue = await totalStakedValue();
      const totalRewardsUn = await totalRewardsUnclaimed(); 

      setTotalStaked(getStaked);
      setRewards(rewards);
      setStakedValue(stakedValue);
      setTotalRewards(totalRewardsUn);
    }
    catch(err) {
      console.log('err',err);
    }
  }

  useEffect(() => {
    getGenericPortfolio()
  },[]);
  return ( 
    <div className="stake-container">
      <Header />
      <div className="image-div">
        <img src={background} alt="back" className="bg-image" />
      </div>
      <Nft />
      <div className="display-content">
        <div className="display-content-left">
          <div className="display-content-left-heading1">Stats</div>
          <div className="display-content-left-container1">
            <Graph />
            <Generic total_staked={total_staked} rewards={rewards} />
          </div>
          <div className="display-content-left-container2">
            <Portfolio stakedValue={stakedValue} totalRewards={totalRewards}/>
          </div>
          <div className="display-content-left-heading2">Perks of Staking</div>
          <div className="display-content-left-container2">
            <Perks
              src={Perk1}
              heading="Get Free NFT Token"
              content="Staking allows the members to be eligible for 3 round of Free NFT Airdrops"
            />
            <Perks
              src={Perk2}
              heading="Chance Of Obtaing Rare NFTs"
              content="The higher the amount of TGK tokens staked, the rarer the NFT a member receives"
            />
            <Perks
              src={Perk3}
              heading="Aquire A Higher APY Structure"
              content="Receive a static 275% APY paid in $TGK as a benefit of staking"
            />
          </div>
          <div className="display-content-left-heading3">FAQ</div>
          <div className="display-content-left-container3">
            <Faq
              title="What’s the mandatory staking period to receive the FREE AIRDROPS?"
              content="A member will only be eligible for the Free TGK NFT Airdrop if their respective tokens are staked on the day of the airdrop. As there are 3 round of Airdrops taking place, members have the choice to stake it for all 3 rounds or less, as they would like."
            />
            <Faq 
                title="For how long is the staking period live?" 
                content="Staking period is live until the end of the 3rd round of airdrop, after which members can move to Minting and/or Trading of the remaining NFTs."
            />
            <Faq
              title="After staking how does one receive their TGK Tokens and NFTs?"
              content="TGK tokens will be available to claim on the same dApp and NFTs will be airdropped to the member’s respective wallets during the specific timeline."
            />
          </div>
          <div className="display-content-left-heading4">
            For More Info Head Over To Our Telegram
          </div>
          <div>
            <a href='https://t.me/thegamblekingdom' className="join-button">Join The Community</a>
          </div>
        </div>
        <div className="display-content-right">
          <div className="display-content-right-heading1">Stake TGK</div>
          <div className="display-content-right-container1">
            <div className="stake-unstake">
              <div className="b56">
                <button
                  className="b77"
                  id="b77"
                  onClick={() => {
                    setUnstake(false);
                    document.getElementById("b77").classList.add("b77");
                    document.getElementById("b76").classList.add("b76");
                    document.getElementById("b76").classList.remove("b77");
                  }}
                >
                  Stake
                </button>
                <button
                  className="b76"
                  id="b76"
                  onClick={() => {
                    setUnstake(true);
                    document.getElementById("b76").classList.add("b77");
                    document.getElementById("b77").classList.add("b76");
                    document.getElementById("b77").classList.remove("b77");
                  }}
                >
                  Unstake
                </button>
              </div>
            </div>
            {!unstake ? <StakeBox /> : <Unstake />}
          </div>
          <div className="display-content-right-heading2">Rewards</div>
          <div className="display-content-right-container2">
            <Reward />
          </div>
        </div>
      </div>
    </div>
  );
};
