import React from 'react'
import './Follow.css'

export const Follow = ({Src, uri}) => {
  return (
    <a href={uri} className="follow-container" >
        <Src follow-us-on-logo/>
    </a>
  )
}
