import React from 'react';
import Accordion from './Accordion/Accordion';
import './FAQ.css'

const Faq = ({title,content}) => {
  return (
    <div>
      <div className="accordion">
          <Accordion title={title} content={content} />
      </div>
    </div>
  );
};

export default Faq;