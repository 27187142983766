// Images
import pieChart from "../../assets/images/pieChart.png";
import ellipse from "../../assets/svgs/bgEllipseRoadmap.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import upIcon from "../../assets/upIcon.svg";
import { Pagination, Navigation } from "swiper";

// const TokenInfoSupplyElement = ({ supply }) => {
//   return (
//     <div className="token-info-supply-element">
//       <div className="token-info-supply-name">{supply.name}</div>
//       <div className="token-info-supply-percent">{supply.percent}%</div>
//     </div>
//   );
// };
const TokenInfoSupply = ({ supplies }) => {
  return (
    <div className="token-info-supply reward-allocation">
      <div className="body">
        <img
          src={ellipse}
          alt="background ellipse"
          className="background-particle"
        />
        <img src={pieChart} alt="Pie Chart" className="supply-pie-chart" />
        <div className="rewards mobileHid" style={{ height: "600px" }}>
          <div className="img-box-1">
            <img src={upIcon} alt="up" />
          </div>
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            direction={"vertical"}
            className="reward-swiper"
            navigation={{
              prevEl: ".img-box-1-left",
              nextEl: ".img-box-1-right",
            }}
            observer={true}
            observeParents={true}
            parallax={true}
            style={{ height: "600px" }}
            modules={[Pagination, Navigation]}
          >
            {supplies.map((supply) => (
              <SwiperSlide key={supply.name}>
                <div className="reward reward-1">
                  <span>{supply.name}</span>
                  <span>{supply.percent}</span>
                </div>
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Royal Flush</span>
                      <span>$125,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Straight Flush</span>
                      <span>$100,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Four of a Kind</span>
                      <span>$90,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Full House</span>
                      <span>$70,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-4">
                      <span>Flush</span>
                      <span>$50,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Straight</span>
                      <span>$40,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Three of a kind </span>
                      <span>$25,000</span>
                    </div>
                  </SwiperSlide> */}
          </Swiper>
          <div className="img-box-1">
            <img src={upIcon} alt="up" />
          </div>
        </div>
        <div className="rewardsRow mobileVis">
          <div className="img-box-1-left">
            <img src={upIcon} alt="up" />
          </div>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              prevEl: ".img-box-1-left",
              nextEl: ".img-box-1-right",
            }}
            // direction={"vertical"}
            className="reward-swiper-row"
            // style={{ height: "600px" }}
            modules={[Pagination, Navigation]}
          >
            {/* <TokenInfoSupplyElement supply={supply} key={supply.name} /> */}
            {supplies.map((supply) => (
              <SwiperSlide key={supply.name}>
                <div className="reward reward-1">
                  <span>{supply.name}</span>
                  <span>{supply.percent}</span>
                </div>
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
                    <div className="reward reward-1">
                      <span>Straight Flush</span>
                      <span>$100,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Four of a Kind</span>
                      <span>$90,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-2">
                      <span>Full House</span>
                      <span>$70,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-4">
                      <span>Flush</span>
                      <span>$50,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Straight</span>
                      <span>$40,000</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="reward reward-3">
                      <span>Three of a kind </span>
                      <span>$25,000</span>
                    </div>
                  </SwiperSlide> */}
          </Swiper>
          <div className="img-box-1-right">
            <img src={upIcon} alt="up" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfoSupply;
