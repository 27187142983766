import React from 'react';
import './Wallet_Name.css';

function WalletName({name,src}){
    return(
        <div className="wallet-name-container">
            <div className="wallet-conatiner-left">
                <img src={src} alt="img" />
            </div>
            <div className="wallet-container-right">
                {name}
            </div>
        </div>
    )
}

export default WalletName;