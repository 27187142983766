import React from "react";
import { Toggle_Button } from "../Toggle_Button/Toggle_Button";
import "./Setting.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { ExpertMode } from "../Expert_Mode/Expert_Mode";
import close from "../../assets/close.png";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        backdropFilter: "blur(15px)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
    paper: {
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
    },
}));

export const Setting = ({
    slippage,
    setSlippage,
    txDuration,
    setTxDuration,
    expertMode,
    setExpertMode
}) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleExpertMode = (e) => {
        // e.stopPropagation();
        // setOpen(true);
        setExpertMode(!expertMode)
        // document.querySelector(".jss6").setAttribute("style", "visibility: hidden");
    };
    const handleClose = (e) => {
        // e.stopPropagation();
        // setOpen(false);
        setExpertMode(false)
        // document.querySelector(".jss6").removeAttribute("style", "visibility: hidden");
        // document.querySelector(".jss6").setAttribute("style","top: 50%; left: 50%; transform: translate(-50%, -50%)");
    };

    return (
        <div className="transaction-container">
            <div className="setting-container">
                <div className="conatiner-heading">Transaction Settings</div>
                <div className="slippage-tolerance">
                    <div className="slippage-tolerance-heading">
                        Slippage tolerance
                    </div>
                    <div className="slippage-tolerance-input">
                        {/* <div className="b-auto"><button className="b12">Auto</button></div> */}
                        <div className="text">
                            <input
                                type="text"
                                placeholder="1.00"
                                value={slippage}
                                onChange={(e) => setSlippage(e.target.value)}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="transaction-deadline">
                    <div className="transaction-deadline-heading">
                        Transaction deadline
                    </div>
                    <div className="transaction-input">
                        <input
                            type="text"
                            placeholder="5.00"
                            value={txDuration}
                            onChange={(e) => setTxDuration(e.target.value)}
                        ></input>
                    </div>
                </div>
                <div className="expert-mode">
                    <div className="expert-mode-left">Expert Mode</div>
                    <div className="expert-mode-right" onClick={handleExpertMode}>
                        <Toggle_Button expertMode={expertMode}/>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={open}
                            className={classes.root}
                        >
                            <div style={modalStyle} className={classes.paper}>
                                <div className="close">
                                    <img
                                        src={close}
                                        alt="close"
                                        className="close-button"
                                        // onClick={handleClose}
                                    />
                                </div>
                                {/* <ExpertMode handleExpertMode={handleExpertMode} setOpen={setOpen} setExpertMode={setExpertMode}/> */}
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};
