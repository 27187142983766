import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    
    elements: {
        point: {
            radius: 0,
        },
    },
    plugins: {
        title: {
            display: false,
        },legend: {
          display: false
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                color: "#D1A94E ",
                borderColor: "#D1A94E",
            },
        },
        y: {
            grid: {
                display: false,
                color: "#D1A94E",
                borderColor: "#D1A94E",
            },
        },
    },
};

const labels = ["a", "b", "c", "d", "e", "f", "g"];

export const data = {
    labels,
    datasets: [
        {
            // label: "Tgk",
            data: [10000, 20000, 30000, 40000, 5000, 6000, 70000, 80000],
            borderColor: "#D1A94E",
            borderWidth: "2",
        },
    ],
};

const Chart = () => {
    return <Line options={options} data={data} />;
};

export default Chart;
