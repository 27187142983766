import React, { useEffect } from "react";
import "./Toggle_Button.css";
import ToggleButton from "react-toggle-button";
const borderRadiusStyle = { borderRadius: 2 };
export const Toggle_Button = ({expertMode}) => {
  const [state,setState] = React.useState(expertMode)

    return (
        <ToggleButton
            value={state || false}
            thumbStyle={borderRadiusStyle}
            trackStyle={borderRadiusStyle}
            onToggle={(value) => {
                setState(!expertMode);
            }}
        />
    );
};
