import React from 'react'
import './MintPerk.css'

export const MintPerk = ({src,heading,subheading}) => {
  return (
    <div className="mint-perk-container">
        <div className="mint-perk-image">
            <img src={src} className='p4'/>
        </div>
        <div className="mint-perk-content">
            <div className="mint-perk-content-heading">
                {heading}
            </div>
            <div className="mint-perk-content-subheading">
               {subheading}
            </div>
        </div>

    </div>
  )
}
