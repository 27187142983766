// Lib
import { useState } from "react";

// CSS
import "./TokenInfo.css";

// Images
import hyperLink from "../../assets/openLink.svg";

// Components
import TokenInfoSupply from "./TokenInfoSupply";
import guidlines from '../../assets/files/TGK GAMING AND GUIDELINES .pdf'

const TokenInfoNavOption = ({ nav, current, i, setCurrent }) => {
  if (nav.type === "link") {
    return (
      <div className="token-info-navigation-option">
        <a href={nav.uri} download={nav.name + '.pdf'} >{nav.name}</a>
        <img src={hyperLink} alt="Hyperlink" className="hyper-link-logo" />
      </div>
    );
  }
  const className = current === i ? " current" : "";
  return (
    <div
      className={"token-info-navigation-option" + className}
      onClick={() => setCurrent(i)}
    >
      {nav.name}
    </div>
  );
};

// Token Info Element
const TokenInfoElement = ({ element }) => {
  return (
    <div className="token-info-element">
      <img
        src={element.image}
        alt={element.title}
        className="token-info-element-image"
      />
      <h2 className="token-info-element-heading">{element.title}</h2>
      <p className="token-info-element-info">{element.text}</p>
    </div>
  );
};

// Token Info
const TokenInfo = ({ navList, elements, supplies }) => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      <div className="token-info-heading main-heading">Token Information</div>
    <section className="token-info">
      <nav className="token-info-navigation noScroll">
        {navList.map((nav, i) => (
          <TokenInfoNavOption
            key={nav.name}
            nav={nav}
            current={current}
            i={i}
            setCurrent={setCurrent}
          />
        ))}
      </nav>
      {current === 0 ? (
        <>
        <div className="token-info-details"></div>
        <div className="token-info-elements">
          {elements.map((element) => (
            <>
            <div className="token-info-element token-info-element-hide-bg"></div><TokenInfoElement element={element} key={element.title} /></>
            
          ))}
        </div>
        </>
      ) : (
        <TokenInfoSupply supplies={supplies} />
      )}
      <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7eba035e8bab87c703bb3fc3aedc3b81b614a6c9"  className="btn-outline">
        View Chart
        <img src={hyperLink} alt="Hyperlink" />
      </a>
    </section>
    </>

  );
};

export default TokenInfo;
