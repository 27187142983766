import React, { useEffect } from "react";
import "./Coin.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const coinList = ["eth", "usdt"];
function CoinDropdown({ src,src2, name, disabled, state, setState, setCoinListValue, coinListValue }) {
    // handle dropdowns

    const handleChange = (event) => {
      setCoinListValue(event.target.value);
    };
    return (
        <div className="coin-container">
            <div className="coin-container-left">
                <input
                    // disabled={disabled}
                    onChange={(e) => setState(e.target.value)}
                    type="number"
                    placeholder="0.00"
                    value={state}
                    name={state}
                />
            </div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={coinListValue}
                label={coinListValue}
                onChange={handleChange}
                className="coin-container-right"
            >
                {coinList.map((item, index) => {
                    return (
                        <MenuItem key={`${item}${index}`} value={item}>
                            <div className="coin-container-right">
                                <img src={item == "eth" ? src : src2} alt="img" />
                                <span className="coin-container-right-name">
                                    {item}
                                </span>
                            </div>
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
}

{
    /* <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={age}
    label="Age"
    onChange={handleChange}
>
    {coinList.map((item, index) => {
        return <MenuItem value={item}>{item}</MenuItem>;
    })}
</Select>; */
}

export default CoinDropdown;
