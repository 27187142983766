import React, { useContext } from "react";
import "./Header.css";
import logo from "../../assets/logo.webp";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ConnectWallet from "../Connect_Wallet/Connect_Wallet";
import close from "../../assets/close.png";
import { Link } from "react-router-dom";
import mobileLogo from '../../assets/TGK_Logo_Gold-Texture_2.webp'
import { Web3Context } from "../../context/Web3Context";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backdropFilter: "blur(15px)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    position: "absolute",
    backgroundColor: "transparent",
    border: "none",
  },
}));

function Header() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const { isWalletConnected,metamaskAddress } = useContext(Web3Context)

  const handleConnectWallet = () => {
    setOpen(true);
  };
  const handleConnectWalletClose = () => {
    setOpen(false);
  };

  return (
    <div className="header">
      <Link to={"/"} className="logo">
        <img src={logo} alt='' className="header-logo pc" />
        <img src={mobileLogo} alt='' className="header-logo mobile" />
      </Link>
      <div className="header-button">

        {isWalletConnected
          ? <button className="header-menu">
            {metamaskAddress?.slice(0,4)}...{metamaskAddress?.slice(-6)}
          </button>
          : <button className="header-menu" onClick={handleConnectWallet}>
            Connect Wallet
          </button>
        }
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        className={classes.root}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="close">
            <img
              src={close}
              alt="close"
              className="close-button"
              onClick={handleConnectWalletClose}
            />
          </div>
          <ConnectWallet keyValue={3} onClose={handleConnectWalletClose} />
        </div>
      </Modal>
    </div>
  );
}

export default Header;
